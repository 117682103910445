var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/home"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-menu-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Inicio")])])])]}}])}),_c('router-link',{attrs:{"to":"/xxx"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers"}),_c('span',{staticClass:"menu-text"},[_vm._v("Courses")])])])]}}])}),_c('router-link',{attrs:{"to":"/xxx"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-infographic"}),_c('span',{staticClass:"menu-text"},[_vm._v("Sala de batalla")])])])]}}])}),_c('li',{staticClass:"menu-item menu-clickable",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('div',{staticClass:"menu-link",on:{"click":function($event){return _vm.showReferences()}}},[_c('i',{staticClass:"menu-icon flaticon2-settings"}),_c('span',{staticClass:"menu-text"},[_vm._v("Preferences")])])]),_c('li',{staticClass:"menu-item menu-clickable",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('div',{staticClass:"menu-link",on:{"click":function($event){return _vm.showEditProfile()}}},[_c('i',{staticClass:"menu-icon flaticon-user"}),_c('span',{staticClass:"menu-text"},[_vm._v("Edit Profile")])])]),_c('li',[_c('div',{staticClass:"logout-action",on:{"click":_vm.onLogout}},[_c('span',{staticClass:"material-icons mr-3 mb-0"},[_vm._v(" meeting_room ")]),_c('h4',{staticClass:"mr-7 mb-0"},[_vm._v("Logout")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }