<template>
  <ul class="menu-nav">
    <router-link
      to="/home"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-menu-2"></i>
          <span class="menu-text">Inicio</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/xxx"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers"></i>
          <span class="menu-text">Courses</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/xxx"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-infographic"></i>
          <span class="menu-text">Sala de batalla</span>
        </a>
      </li>
    </router-link>

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-clickable"
      >
        <div class="menu-link" @click="showReferences()">
          <i class="menu-icon flaticon2-settings"></i>
          <span class="menu-text">Preferences</span>
        </div>
      </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-clickable"
    >
      <div class="menu-link" @click="showEditProfile()">
        <i class="menu-icon flaticon-user"></i>
        <span class="menu-text">Edit Profile</span>
      </div>
    </li>



    <li>
      <div class="logout-action" @click="onLogout">
        <span class="material-icons mr-3 mb-0">
        meeting_room
        </span>
        <h4 class="mr-7 mb-0">Logout</h4>
      </div>
    </li>
  </ul>


</template>

<style scoped>

.days-result-content .d-block {
  flex-flow: column;
  display: flex!important;
  padding-left: 35px;
}

.days-result-content .d-block div{
  margin-bottom: 8px;
  font-size: 14px;
}

.days-result-content .d-block div img {
  margin-right: 16px;
}

.logout-action{
    height: 50px;
    width: 200px;
    background: #FFE4E6;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
}
.menu-item.menu-item-active > .menu-link {
    background-color: #E0F2FE !important;
    opacity: 1 !important;
}
.menu-item>.menu-link {
    opacity: 0.4 !important;
}

.menu-item.menu-clickable>.menu-link {
  opacity: 1!important;
}
.menu-text{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 15px;
  /* identical to box height, or 100% */
  color: #0B2343 !important;
}
.aside-menu .menu-nav > .menu-item {
  background-color: #F0F9FF !important;
  margin-bottom: 3px;
}
.aside-menu .menu-nav > .menu-item .menu-link {
  padding: 20px;
}
.menu-item :hover{
   background-color: #E0F2FE !important;
}

.timer-widget {
  padding: 16px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.timer-widget > .description {
  text-align: center;
  margin-top: 16px;
}

.timer-widget > .showtimeline {
  text-align: center;
  color: #007CC2;
  margin-top: 8px;
  font-size: 12px;
  cursor: pointer;
}

.timer-result {
  display: flex;
}

.timer-result > .description {
  padding: 20px;
  font-size: 16px;
}

.timer-result > .description > .title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #FF6A6A;
}

.days-title {
  color: #FFE600
}

.days-result-content .days {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  border-bottom: 1px #D9D9D9 solid;
  overflow-y: auto;
}

.days-result-content .days .day-items {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
  margin: 8px;
}

.days-result-content .days .day-items img {
  width: 25px;
  height: 25px;
}

.days-result-content .days .day-items p {
  width: 50px;
  text-align: center;
}
</style>
<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  data() {
    return {

    }
  },
  created() {
    
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
  },
watch: {
  
},
computed: {
    ...mapGetters([
        "getRecordingStatus",
        "currentUser"
    ]),
   }
};
</script>
